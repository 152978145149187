import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/compat/firestore";

@Injectable({
    providedIn: "root",
})
export class FirebaseService {
  constructor(private firestore: AngularFirestore) {}

  addData(collection: string, data: any) {
    debugger;
    return this.firestore.collection(collection).add(data);
  }
}

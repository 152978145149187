import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PreloaderComponent } from './components/layouts/preloader/preloader.component';
import { FooterComponent } from './components/layouts/footer/footer.component';
import { HomeFourComponent } from './components/pages/home-four/home-four.component';
import { NavbarOneComponent } from './components/layouts/navbar-one/navbar-one.component';
import { AboutComponent } from './components/pages/about/about.component';
import { ProjectsTwoComponent } from './components/pages/projects-two/projects-two.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { BlogRightSidebarComponent } from './components/pages/blog-right-sidebar/blog-right-sidebar.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { FrontendDevlopmentComponent } from './components/pages/frontend-devlopment/frontend-devlopment.component';
import { BackendDevelopmentComponent } from './components/pages/backend-development/backend-development.component';
import { AppDevelopmentComponent } from './components/pages/app-development/app-development.component';
import { BlockchainComponent } from './components/pages/blockchain/blockchain.component';
import { CarrersComponent } from './components/pages/carrers/carrers.component';
import { ReactiveFormsModule } from '@angular/forms';
import { GraphicDesignComponent } from './components/pages/graphic-design/graphic-design.component';
import { ECommerceComponent } from './components/pages/e-commerce/e-commerce.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';
import { TermsAndConditionsComponent } from './components/pages/terms-and-conditions/terms-and-conditions.component';
import { HtmlComponent } from './components/pages/html/html.component';
import { CssComponent } from './components/pages/css/css.component';
import { AndroidComponent } from './components/pages/android/android.component';
import { AngularComponent } from './components/pages/angular/angular.component';
import { AwsComponent } from './components/pages/aws/aws.component';
import { AzureComponent } from './components/pages/azure/azure.component';
import { CPlusComponent } from './components/pages/c-plus/c-plus.component';
import { CSharpComponent } from './components/pages/c-sharp/c-sharp.component';
import { DotNetComponent } from './components/pages/dot-net/dot-net.component';
import { JavaComponent } from './components/pages/java/java.component';
import { JavascriptComponent } from './components/pages/javascript/javascript.component';
import { NextJsComponent } from './components/pages/next-js/next-js.component';
import { NodeJsComponent } from './components/pages/node-js/node-js.component';
import { PythonComponent } from './components/pages/python/python.component';
import { ReactJsComponent } from './components/pages/react-js/react-js.component';
import { ReactNativeComponent } from './components/pages/react-native/react-native.component';
import { SwiftComponent } from './components/pages/swift/swift.component';
import { ExpressComponent } from './components/pages/express/express.component';
import { KotlinComponent } from './components/pages/kotlin/kotlin.component';
import { environment } from 'src/environments/environment';
import { AngularFireModule } from "@angular/fire/compat";
import { AngularFirestoreModule } from "@angular/fire/compat/firestore";

@NgModule({
    declarations: [
        AppComponent,
        PreloaderComponent,
        FooterComponent,
        HomeFourComponent,
        NavbarOneComponent,
        AboutComponent,
        ProjectsTwoComponent,
        ContactComponent,
        BlogRightSidebarComponent,
        ErrorComponent,
        FrontendDevlopmentComponent,
        BackendDevelopmentComponent,
        AppDevelopmentComponent,
        BlockchainComponent,
        CarrersComponent,
        GraphicDesignComponent,
        ECommerceComponent,
        PrivacyPolicyComponent,
        TermsAndConditionsComponent,
        HtmlComponent,
        CssComponent,
        JavascriptComponent,
        ReactJsComponent,
        NextJsComponent,
        AngularComponent,
        AndroidComponent,
        CPlusComponent,
        AwsComponent,
        AzureComponent,
        DotNetComponent,
        NodeJsComponent,
        PythonComponent,
        CSharpComponent,
        JavaComponent,
        SwiftComponent,
        ReactNativeComponent,
        ExpressComponent,
        KotlinComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        ReactiveFormsModule,
        AngularFireModule.initializeApp(environment.firebaseConfig),
        AngularFirestoreModule,
    ],
    providers: [],
    bootstrap: [AppComponent],
})
export class AppModule {}

<app-navbar-one></app-navbar-one>
<div class="page-title-area item-bg-2">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Portfolio</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Portfolio</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<section class="agency-services-section ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>A Gallery of Excellence: Our Portfolio</h2>
            <div class="bar"></div>
            <p>Our portfolio represents a gallery of excellence, displaying a diverse range of projects that showcase
                our commitment to innovation, expertise, and dedication to success. Each item within our portfolio tells
                a unique story of passion, creativity, and proficiency, demonstrating our ability to exceed expectations
                and deliver outstanding results.</p>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-agency-services">
                    <a href="https://vertexmarket-ecommerce.web.app/" target="_blank">
                        <div class="image">
                            <img src="assets/img/protfolio/pro1.webp" alt="VertexMarket - ECommerce">
                        </div>
                        <div class="content" style="padding: 17px;">
                            <h3>VertexMarket - ECommerce</h3>
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-agency-services">
                    <a href="https://axion-dealscoupons.web.app/" target="_blank">
                        <div class="image">
                            <img src="assets/img/protfolio/pro2.webp" alt="image" style="height: 230px;">
                        </div>
                        <div class="content" style="padding: 20px;">
                            <h3>Axion Deals coupons</h3>
                        </div>
                    </a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-agency-services"> <a href="https://wellnessa-hospital.web.app/" target="_blank">
                        <div class="image"> <img src="assets/img/protfolio/pro3.webp" alt="image"
                                style="height: 230px;">
                        </div>
                        <div class="content" style="padding: 20px;">
                            <h3>Wellnessa Hospital</h3>
                        </div>
                    </a> </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-agency-services"> <a href="https://digipluse-digital-market.web.app/"
                        target="_blank">
                        <div class="image"> <img src="assets/img/protfolio/pro4.webp" alt="image"
                                style="height: 230px;">
                        </div>
                        <div class="content" style="padding: 20px;">
                            <h3>Digipluse Digital-Market</h3>
                        </div>
                    </a> </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-agency-services"> <a href="https://luxehavens-hotel.web.app/" target="_blank">
                        <div class="image"> <img src="assets/img/protfolio/pro5.webp" alt="image"
                                style="height: 230px;">
                        </div>
                        <div class="content" style="padding: 20px;">
                            <h3>Luxehavens Hotel</h3>
                        </div>
                    </a> </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-agency-services"> <a href="https://tradermindtech-stackmarket.web.app/"
                        target="_blank">
                        <div class="image"> <img src="assets/img/protfolio/pro6.webp" alt="image"
                                style="height: 230px;">
                        </div>
                        <div class="content" style="padding: 20px;">
                            <h3>TraderMindTech</h3>
                        </div>
                    </a> </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-agency-services"> <a href="https://cryptonexus-blockchain.web.app/" target="_blank">
                        <div class="image"> <img src="assets/img/protfolio/pro7.webp" alt="image"
                                style="height: 230px;">
                        </div>
                        <div class="content" style="padding: 20px;">
                            <h3>CryptoNexus-Blockchain</h3>
                        </div>
                    </a> </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-agency-services"> <a href="https://medixo-hospital.web.app/" target="_blank">
                        <div class="image"> <img src="assets/img/protfolio/pro8.webp" alt="image"
                                style="height: 230px;">
                        </div>
                        <div class="content" style="padding: 20px;">
                            <h3>Medixo Hospital</h3>
                        </div>
                    </a> </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-agency-services"> <a href="https://delmonte-ecommerce.web.app/" target="_blank">
                        <div class="image"> <img src="assets/img/protfolio/pro9.webp" alt="image"
                                style="height: 230px;">
                        </div>
                        <div class="content" style="padding: 20px;">
                            <h3>Delmonte Ecommerce</h3>
                        </div>
                    </a> </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-agency-services"> <a href="https://trustx-business.web.app/" target="_blank">
                        <div class="image"> <img src="assets/img/protfolio/pro10.webp" alt="image"> </div>
                        <div class="content" style="padding: 20px;">
                            <h3>Trustx Business</h3>
                        </div>
                    </a> </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-agency-services"> <a href="https://formoney-bussiness.web.app/" target="_blank">
                        <div class="image"> <img src="assets/img/protfolio/pro11.webp" alt="image" style="height:230px">
                        </div>
                        <div class="content" style="padding: 20px;">
                            <h3>Formoney Business</h3>
                        </div>
                    </a> </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-agency-services"> <a href="https://healthcare-clinic.web.app" target="_blank">
                        <div class="image"> <img src="assets/img/protfolio/pro12.webp" alt="image" style="height:230px">
                        </div>
                        <div class="content" style="padding: 20px;">
                            <h3>Healthcare Clinic</h3>
                        </div>
                    </a> </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-agency-services"> <a href="https://webqa-organization.web.app/" target="_blank">
                        <div class="image"> <img src="assets/img/protfolio/pro13.webp" alt="image" style="height:230px">
                        </div>
                        <div class="content" style="padding: 20px;">
                            <h3>WebQa Qrganization</h3>
                        </div>
                    </a> </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-agency-services"> <a href="https://cybershield-datasecurity.web.app/" target="_blank">
                        <div class="image"> <img src="assets/img/protfolio/pro14.webp" alt="image" style="height:230px">
                        </div>
                        <div class="content" style="padding: 20px;">
                            <h3>Cybershield Datasecurity</h3>
                        </div>
                    </a> </div>
            </div>
        </div>
    </div>
</section>
import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { FirebaseService } from "src/app/services/firebase.service";

@Component({
    selector: "app-contact",
    templateUrl: "./contact.component.html",
    styleUrls: ["./contact.component.scss"],
})
export class ContactComponent implements OnInit {
    contactForm: FormGroup;

    constructor(private fb: FormBuilder, private firebaseService:FirebaseService) {}

    ngOnInit() {
        this.contactForm = this.fb.group({
            name: ["", [Validators.required]],
            email: ["", [Validators.required, Validators.email]],
            msg_subject: ["", [Validators.required]],
            message: ["", [Validators.required]],
            contact: [""],
            logTime: new Date(),
            website: ["https://kautilyam.com"],
        });
    }

    submitForm() {
        debugger;
        if (this.contactForm.valid) {
            this.firebaseService
                .addData("formData", this.contactForm.value)
                .catch((error) => {
                    console.error("Error adding data: ", error);
                });
            this.contactForm.reset();
        } else {
            this.validateAllFormFields(this.contactForm);
            console.log("Form is invalid", this.contactForm.errors);
        }
    }

    private validateAllFormFields(formGroup: FormGroup) {
        Object.keys(formGroup.controls).forEach((field) => {
            const control = formGroup.get(field);
            if (control instanceof FormGroup) {
                this.validateAllFormFields(control); // Recursive validation for nested form groups
            } else {
                control.markAsTouched({ onlySelf: true }); // Mark the field as touched to show validation messages
            }
        });
    }
}
